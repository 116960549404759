import React from "react";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

import {
	lookupOrgIdByAccountIdOrAlias,
} from "../../external-apis";
import Loader from "../../components/Loader";
import useQueryWithoutAuthorization from "../../custom-hooks/external-api-hooks/useQueryWithoutAuthorization";

const ProtectedRoute = ({ Component }) => {
	const { accountAlias } = useParams();

	const {
		isLoading,
		isError,
		data: organizationId,
		error
	} = useQueryWithoutAuthorization(['orgId', accountAlias], lookupOrgIdByAccountIdOrAlias(accountAlias),{
		staleTime: 0,
		refetchOnWindowFocus: false,
	});

	if (isLoading) return <Loader/>;
	if (isError) {
		console.warn('Failed to lookup for organization ID', error.message);

		return <Navigate to={'/recover-account'} replace/>;
	}

	const ProtectedComponent = withAuthenticationRequired(Component, {
		onRedirecting: () => <Loader/>,
		loginOptions: {
			organization: organizationId,
			// appState: {
			// }
		}
	});

	return <ProtectedComponent/>;
};

export default ProtectedRoute;
