import './config';
import './assets/css/fonts.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { setConfig } from "./config";
import { AccountPage, Auth0CallbackPage, LoginPage, InvitationPage, NotFoundPage, HomePage, ProductLauncherPage } from "./pages";
import ProtectedRoute from "./auth/ProtectedRoute";
import SignupPage from "./pages/SignupPage";
import AppWrapper from "./AppWrapper";
import LoginHome from './pages/LoginHome';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import DeveloperPortalPage from "./pages/DeveloperPortalPage";
import LogoutPage from "./pages/LogoutPage";
import RecoverAccountPage from "./pages/RecoverAccountPage";
import ExpiredInvitationPage from "./pages/ExpiredInvitationPage";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import ApplicationsPage from './pages/ApplicationsPage';
import AuditPage from "./pages/AuditPage";
import { getDomain } from './config';
import analytics from "@matisse/portal-analytics";

const AppContainer = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	backgroundColor: '#FFFFFF',

	// TODO: replace/remove when implementing small screen design (minWidth, overflow)
	// minWidth: theme.breakpoints.values.sm,
	// overflow: 'hidden',
}));

function App() {
	// Ensure initialisation of the config via dynamic loading of the config.json
	const [configLoaded, setConfigLoaded] = useState();

	useEffect(() => {
		fetch("/config.json")
			.then(response => response.json())
			.then(configValue => {
				const isConfigUpdated = setConfig(configValue);

				if (isConfigUpdated) {
					const gtmAuth = configValue.isProdDeployment ? '' : configValue.gtmAuth;
					const gtmPreview = configValue.isProdDeployment ? '' : configValue.gtmPreview;

					analytics.common.initialize(configValue.gtmContainerId, configValue.isProdDeployment, gtmAuth, gtmPreview);
				}
				setConfigLoaded(isConfigUpdated);
			})
			.catch(console.error);
	}, []);

	return configLoaded ? (
		<AppWrapper>
			<AppContainer>
				<Routes>
					<Route exact path="/login/docs" element={<LoginHome redirectTo={`https://docs.${getDomain()}/api/auth/login`}/>}/>
					<Route exact path="/login/developerPortal" element={<LoginHome navigateTo={"developerPortal"}/>}/>
					<Route exact path="/:accountAlias/developerPortal" element={<ProtectedRoute Component={DeveloperPortalPage}/>}/>
					{/* The '/callback' route is used for redirecting from auth0 while 'onRedirectCallback' is triggered */}
					<Route exact path="/callback" element={<Auth0CallbackPage/>}/>
					<Route exact path="/loginhome" element={<LoginHome/>}/>
					<Route exact path="/login" element={<LoginPage/>}/>
					<Route exact path="/logout" element={<LogoutPage/>}/>
					<Route exact path="/invite" element={<InvitationPage/>}/>
					<Route exact path="/inviteExpired" element={<ExpiredInvitationPage/>}/>
					<Route exact path="/accessDenied" element={<AccessDeniedPage/>}/>
					<Route exact path="/signup" element={<SignupPage/>}/>
					<Route exact path="/" element={<LoginPage/>}/>
					<Route exact path="/recover-account" element={<RecoverAccountPage/>}/>
					<Route exact path="/:accountAlias" element={<Navigate to="home"/>}/>
					<Route exact path="/:accountAlias/home" element={<ProtectedRoute Component={HomePage}/>}/>
					<Route exact path="/:accountAlias/account">
						<Route exact path="users" element={<ProtectedRoute Component={AccountPage}/>}/>
						<Route exact path="applications" element={<ProtectedRoute Component={ApplicationsPage}/>}/>
						<Route exact path="audit" element={<ProtectedRoute Component={AuditPage}/>}/>
						<Route index element={<Navigate to="users"/>}/>
					</Route>
					<Route exact path="/:accountAlias/launchProduct/:tenantId" element={<ProductLauncherPage/>}/>
					{/* <Route exact path="/launchProduct/:tenantId" element={<ProductLauncherPage/>}/> */}
					<Route path="*" element={<NotFoundPage/>}/>
				</Routes>
				<ToastContainer/>
			</AppContainer>
		</AppWrapper>
	) : null;
}

export default App;
