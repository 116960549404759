import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import useStateWithValidation from "../../custom-hooks/useStateWithValidation";
import AppButton from "../../components/AppButton";
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import cookiesNames from "../../config/cookies";
import AppInput from "../../components/AppInput";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: "center",
		width: 420,
		height: 400,
		margin: "0 auto 0 auto"
	},
	formContainer: {
		width: 372,
		display: "flex",
		flexDirection: "column",
		alignSelf: "center"

	},
	titleContainer: {
		width: 346,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: "center",
		marginBottom: 45
	},
	userInputContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	setupAccountButton: {
		width: '100%',
		height: 40,
		marginTop: 35,
		marginBottom: 40,
		fontWeight: 400,
		fontFamily: 'Source Sans Pro',
		zIndex: 10
	},
	labelWelcome: {
		height: 84,
		width: '100%',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 28,
		textAlign: 'center',
		color: '#11161D',
	},
	label: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
	},
	input: {
		width: '100%'
	},
	inputLabel:{
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		textAlign: 'center',
		color: '#293447',
	},
	forgotAccountBtn:{
		color: '#2966CC',
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		fontWeight: 400,
		alignSelf: 'center'

	},
	forgotAccountBtnContainer:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

	},
	requestTrialBtnContainerCol: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	requestTrialBtnContainerRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	requestTrialLink: {
		color: '#2966CC',
		textDecoration: 'none',
		marginLeft: 6
	},
	requestTrialText:{
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		fontWeight: 400,
		alignSelf: 'center',
		color: '#212B3A',
	}
}));

const LoginHome = ({ navigateTo, redirectTo }) => {

	const styles = useStyles();
	const [cookies] = useCookies([cookiesNames.accountAlias]);
	const accountAliasCookie = cookies?.[cookiesNames.accountAlias];
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [accountAlias, setAccountAlias, isValidAlias] = useStateWithValidation('', alias => alias?.trim());
	const [forgotAccountActive, setForgotAccountActive] = useState(false);
	const handleLoginClick = () => {
		redirectTo ? window.location.assign(`${redirectTo}?account=${accountAlias}`) : navigateTo ? navigate(`/${accountAlias}/${navigateTo}?${searchParams}`) : navigate(`/${accountAlias}`);
	};
	const handleForgotAccount = () => {
		setForgotAccountActive(true);
	};

	if (accountAliasCookie) {
		if (redirectTo) {
			window.location.assign(`${redirectTo}?account=${accountAlias}`);

			return null;
		}

		return navigateTo ? <Navigate to={`/${accountAliasCookie}/${navigateTo}?${searchParams}`} replace/> : <Navigate to={`/${accountAliasCookie}`} replace/>;
	}
	const form = (
		<div className={styles.formContainer}>
			<div className={styles.userInputContainer}>
				<AppInput
					autoFocus
					value={accountAlias}
					id={'account_alias'}
					name={'account-name'}
					label={'Account name'}
					onChange={setAccountAlias}
					error={!isValidAlias}
					errorMessage={'Please insert a valid value'}
					onEnterPress={handleLoginClick}
					placeholder={'Enter your account name'}
					overrideClasses={{ input: styles.input, label: styles.inputLabel }}
				/>
			</div>
			<div className={styles.forgotAccountBtnContainer}>
				<AppButton
					variant={'text'}
					overrideClasses={{ button: styles.forgotAccountBtn }}
					id={'LoginHome_forgotAccount_btn'}
					onClick={handleForgotAccount}
				>
					Forgotten your account?
				</AppButton>
			</div>
			<AppButton
				variant={'contained'}
				disabled={!isValidAlias}
				type={'submit'}
				overrideClasses={{ button: styles.setupAccountButton }}
				id={'btn_login'}
				onClick={handleLoginClick}
			>
				Sign In
			</AppButton>
			<div id={'loginHome_requestTrial_container'} className={styles.requestTrialBtnContainerCol}>
				<div className={styles.requestTrialBtnContainerRow}>
					<div  id={'loginHome_requestTrial_text'} className={styles.requestTrialText}>Don&apos;t have an account?&nbsp; </div>
					<a id={'loginHome_requestTrial_link'} target={"_blank"} className={styles.requestTrialLink} href={`https://www.synamedia.com/contact/`} rel="noreferrer">Request a trial</a>
				</div>
			</div>
		</div>
	);

	const content = (
		<div className={styles.rootContainer}>
			<div className={styles.titleContainer}>
				<div className={styles.labelWelcome} id={'LoginHome_labelWelcome'}>Welcome to <br/>Synamedia Cloud Portal</div>
				<div className={styles.label}  id={'LoginHome_label'}>Please enter your account name</div>
			</div>
			{form}
		</div>
	);

	document.title = 'Synamedia Cloud Portal | Login';

	return (forgotAccountActive? <Navigate to={`/recover-account`}/> :
		<SynamediaAuthContainer
			content={content}
			desc={<div>Your single access point for all your Synamedia products. <br/> Login here to access your products.</div>}
			showTrial={true}
		/>
	);
};

export default LoginHome;
